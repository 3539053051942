body {
	background: #008082;
	font-family: sans-serif, serif, monospace;
	font-size: 13px;
}

.wrapper, .content {
	width: 800px;
	margin: auto;
	margin-top: 20px;
	background: #bfb8bf;
	border: 2px solid;
	border-color: #fff8ff #000000 #000000 #fff8ff;
	padding: 2px 0;
}
.default_title, .post_title {
	padding: 2px 4px 3px 4px;
	position: relative;

	h1 {
		color: #bfb8bf;
		font-size: 14px;
		font-weight: bold;
	}
	
	img {
		float: left;
		width: 14px;
		height: 14px;
		margin: 0 3px 0 0;
	}
}
.post_title {
	h1 {
		display: inline-block;
	}

	a {
		color: #000000;
	}

	.btn {
		background: #cccccc;
		width: 13px;
		height: 11px;
		float: right;
		border: 2px solid;
		border-color: #fff8ff #000000 #000000 #fff8ff;

		span {
			font-size: 11px;
			position: relative;
			left: 2px;
			top: -2px;
		}

		.fa-window-minimize {
			font-size: 10px;
			left: 1px;
		}

		.fa-window-maximize {
			font-size: 10px;
			left: 1px;
		}
	}

	.btn_max {
		margin: 0 3px 0 0;
	}

	.btn:active {
		width: 12px;
		height: 10px;
	}
}
ul.topbar {
	list-style: none;
	padding: 0 10px;
	margin: 3px 0 3px 0;

	li {
		display: inline-block;
		margin: 0 5px 0 0;
	}
	a {
		text-decoration: none;
		color: #000000;
	}
}
.post_list, .tag_list, .post_content {
	background: #fff8ff;
	border-width: 2px;
	border-style: ridge groove groove ridge;
	border-color: #7f787f #fff8ff #fff8ff #7f787f;
}
.productCard {
	justify-content: center;
}
.tees button {
	margin-left: 3.5em;
}
.post_list {
	width: 70.2%;
	margin: 0 auto;
	min-height: 150px;
	max-height: 150px;
	overflow-y: scroll;
	display: inline-block;

	ul {
		list-style: none;

		li {
			display: inline-block;
			margin: 10px;
			max-width: 16ch;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: center;

			img {
				display: block;
				margin: 0 auto;
				margin-bottom: 8px;
			}

			a {
				text-decoration: none;
				color: #000000;
			}
		}
	}
	.teeDisplay {
		grid-template-columns: auto auto;
		margin-left: 2.75em;
	}
}
.tag_list {
	width: 28%;
	margin: 0 auto;
	min-height: 150px;;
	max-height: 150px;
	overflow-y: scroll;
	display: inline-block;
	vertical-align: top;
	padding-bottom: 1px;

	img {
		height: 16px;
		width: 16px;
		margin: 0px 5px;
	}

	a {
		text-decoration: none;
		color: #000000;
	}

	ul {
		li {
			max-width: 27ch;
			overflow: visible;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}
#tag-list {
    background:transparent url(/assets/img/pixel-alt.png) repeat-y 15px 0;
}
#tag-list li {
    padding:0 0 0 2px;
}
#tag-list li a {
    background: #fff8ff;
    display:block;
}
#tag-list li ul li {
    background:transparent url(/assets/img/pixel-alt.png) repeat-x 0 8px;
    margin:4px 0 4px 13px;
    padding:0 0 0 20px;
}
.post_total {
	.left, .right {
		border: 1px solid;
		border-color: #888888 #dadada #dadada #888888;
		display: inline-block;
		padding: 1px 3px;
	}
	.left {
		width: 33%;
		margin-left: 2px;
	}
	.right {
		width: 64%;
		margin-right: 2px;
	}
}

.post_content {
	position: relative;
	width: 98.5%;
	min-height: 600px;
	max-height: 600px;
	padding: 2px;
	margin: 0 auto;
	overflow-y: scroll;
	overflow-x: scroll;

	p {
		line-height: 150%;
		margin-bottom: 15px;
	}

	a {
		color: #0000ff;
		margin-bottom: 15px;
	}

	h1 {
		font-size: 20px;
		font-weight: 700;
		margin: 50px 0 10px 0;
	}

	h2 {
		font-size: 18px;
		font-weight: 700;
		margin: 25px 0 5px 0;
	}

	h3 {
		font-size: 16px;
		font-weight: 700;
		margin: 22px 0 3px 0;
	}

	em {
		font-style: italic;
	}

	blockquote {
		padding: 0 0 0 15px;
		margin: 15px 5px;
		border-left: 8px solid #000000;
	}

	strong {
		font-weight: 700;
	}

	hr {
		border-top: 3px solid #333333;
		border-left: 0;
	}

	ul {
		list-style: square;
		margin: 0 0 15px 30px;

		li {
			padding: 5px;
			font-size: 14px;

			ul {
				margin: 10px 0 0 15px;

				li {
					padding: 5px;
					font-size: 14px;
				}
			}
		}
		
	}

	ol {
		list-style: decimal-leading-zero;
		margin: 0 0 15px 30px;

		li {
			padding: 5px;

			ul {
				margin: 10px 0 0 15px;

				li {
					padding: 5px;
				}
			}
		}
	}

	table, th, td {
		border: 1px solid #222222;
		padding: 2px;
	}

	pre {
		margin-bottom: 15px;
	}
	.teeDisplay{
		margin-left: 0.25em;
		padding-left: 0.25em;

	}
}

div img {
	margin-left: 1.5em;
	margin-right: 1.5em;
}



.included li {
	color:blue;
	font-size: 14px;
}
.suggested li {
	color: blueviolet;
	font-size: 14px;
}
.required li {
	color: darkred;
	font-size: 14px;
}

.hljs {
	background: #fff8ff;
}
.footer {
	font-size: 12px;
	margin: 30px 0 10px 0;

	p {
		text-align: center;

		a {
			color: #24244a;
		}
	}
}
.donate {
	float: right;
	padding: 0 3px 0 0;

	p {
		text-align: right;
		word-break: break-word;
	}
}

.tees {
	width: 13.5rem;
	background: #bfb8bf;
	border: 2px solid;
	border-color: #fff8ff #000000 #000000 #fff8ff;
	border-style: ridge groove groove ridge;
	padding: 3px 2px 3px 1px;
	margin: 0px 10px 20px 10px;
	justify-content: center;
	display: inline-flex;
}

.teeDisplay{
	display: inline-flex;
	display: grid;
	grid-template-columns: auto auto auto;

}

.product {
	justify-content: center;
}

@media only screen and (max-width: 900px) {
    .content {
        width: 90%;
	}
	.wrapper {
		width: 90%;
	}
}
@media only screen and (max-width: 700px) {
    .content {
        width: 90%;
    }
	.post_content {
		width: 98%;
	}
}
@media only screen and (max-width: 400px) {
	.post_content {
		width: 97%;
	}
}
@media only screen and (max-width: 810px) {
	.wrapper {
		width: 90%;
	}
	.post_total {
		.left {
			width: 37%;
		}
		.right {
			float: right;
			width: 60%;
		}
	}
	.teeDisplay {
		grid-template-columns: auto auto;
		justify-items: center;

	}
}
@media only screen and (max-width: 750px) {
	.tag_list {
		width: 99%;
		min-height: 80px;
		max-height: 80px;
	}
	.post_list {
		width: 99%;
		float: left;
	}
	.post_list .teeDisplay {
		grid-template-columns: auto auto;
		justify-items: center;

	}

	.post_total {
		.left {
			width: 36%;
		}
		.right {
			float: right;
			width: 60%;
		}
	}
	
	.tees {
		max-width: 60%;
	}
	.tees button {
		margin-left: 1.5em;
	}

}
@media only screen and (max-width: 610px) {
	.tag_list {
		width: 98.7%;
		min-height: 80px;
		max-height: 80px;
	}
	.post_list {
		width: 98.7%;
		float: left;
	}
	.post_list .teeDisplay {
		grid-template-columns: auto;
		justify-items: center;

	}
	.post_total {
		.left {
			width: 35.7%;
		}
	}
	.teeDisplay {
		grid-template-columns: auto auto;
		justify-items: center;

	}
	
}
@media only screen and (max-width: 520px) {
	.post_total {
		.left {
			width: 34.5%;
		}
	}
	.teeDisplay {
		grid-template-columns: auto;
		margin-left: 1.5em;
	}
	.tees button {
		margin-left: 4em;
	}
}
@media only screen and (max-width: 410px) {
	.post_total {
		.left {
			width: 34%;
		}
	}
	
}
@media only screen and (max-width: 375px) {
	.post_total {
		.left {
			width: 33%;
		}
	}
}
::-webkit-scrollbar {
      width: 16px;
} /* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
	background-image: url("/assets/img/track.png");
	background-repeat: repeat-x repeat-y;
} /* the new scrollbar will have a flat appearance with the set background color */
 
::-webkit-scrollbar-thumb {
	background-color: #cccccc;
	border-width: 2px;
	border-style: groove ridge ridge groove;
	border-color: #fff8ff #000000 #000000 #fff8ff;
} /* this will style the thumb, ignoring the track */
 
::-webkit-scrollbar-button {
	background-color: #cccccc;
	border-width: 2px;
	border-style: groove ridge ridge groove;
	border-color: #fff8ff #000000 #000000 #fff8ff;
	background-position: center center;
	background-repeat: no-repeat;

	&:vertical:decrement {
		background-image: url("/assets/img/up.png");
	}

	&:vertical:increment {
		background-image: url("/assets/img/down.png");
	}

	&:horizontal:decrement {
		background-image: url("/assets/img/left.png");
	}

	&:horizontal:increment {
		background-image: url("/assets/img/right.png");
	}

	&:active {
		background-position: 1px 2px;
	}
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */
 
::-webkit-scrollbar-corner {
	background-color: #cacaca;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */

	


.tees h2 {
	text-align: center;
	background: #00007f;
	color: white;
	margin: auto;
	padding: 5px 20px;
	text-transform: uppercase;
	font-size: 1em;
	font-weight: 900;
}

.tees p {
	margin-top: 2px;
	margin-bottom: 0;
	text-align: center;
	text-transform: uppercase;
	font-weight: 600;
}

.tees button {
	align-items: center;
	// color: blue;

	font-size: 0.75em;
	text-transform: uppercase;
	font-weight: 900;
	border: 2px solid;
	
	border-color: #fff8ff #000000 #000000 #fff8ff;
	justify-self: center;
}

.teeImage{
	max-width: 70%;
	width: fit-content;
	justify-self: center;
}



.itemIdentifier {
	display: none;
}

.product .product__information img {
	height: 0.8em;
	width: 0.8em;
	margin-left: -0.77em;
	margin-right: 0.77em;

}

// .modalDialog {
// 	position: fixed;
// 	font-family: Arial, Helvetica, sans-serif;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	background: rgba(0,0,0,0.8);
// 	z-index: 99999;
// 	opacity:0;
// 	-webkit-transition: opacity 400ms ease-in;
// 	-moz-transition: opacity 400ms ease-in;
// 	transition: opacity 400ms ease-in;
// 	pointer-events: none;
// }

// .modalDialog:target {
// 	opacity:1;
// 	pointer-events: auto;
// }

// .modalDialog > div {
// 	width: 400px;
// 	position: relative;
// 	margin: 10% auto;
// 	padding: 5px 20px 13px 20px;
// 	background: #bfb8bf;
// 	border: 2px solid;
// 	border-color: #fff8ff #000000 #000000 #fff8ff;
// 	border-style: ridge groove groove ridge;
	
// }

// .close {
// 	background: #606061;
// 	color: #FFFFFF;
// 	line-height: 25px;
// 	position: absolute;
// 	right: -12px;
// 	text-align: center;
// 	top: -10px;
// 	width: 24px;
// 	text-decoration: none;
// 	font-weight: bold;
// 	-webkit-border-radius: 12px;
// 	-moz-border-radius: 12px;
// 	border-radius: 12px;
// 	-moz-box-shadow: 1px 1px 3px #000;
// 	-webkit-box-shadow: 1px 1px 3px #000;
// 	box-shadow: 1px 1px 3px #000;
// }

// .close:hover { background: #00d9ff; }